<template>
  <card>
    <div class="header-courses">
      <h3>
        {{ title.title }}
        <span>({{ title.subtitle }})</span>
      </h3>
      <div class="row">
        <div class="col-8">
          <span>{{ subTitle.leftTitle }}</span>
        </div>
        <div class="col-4">
          <span>{{ subTitle.rightTitle }}</span>
        </div>
      </div>
    </div>
    <ul class="list-group list-group-flush list my--3">
      <li v-for="(course, index) in list" :key="index" class="list-group-item px-0">
        <div class="row align-items-center">
          <div class="col-auto">
            <!-- Avatar -->
            <a class="avatar">
              <img alt="course" :src="course.photo_url" />
            </a>
          </div>
          <div class="col-7">
            <h4 class="mb-0">{{ course.name }}</h4>
            <small class="d-flex align-items-center">
              <svg class="custom-icon star">
                <use href="#star" />
              </svg>
              {{ course.rating }} ● {{ course.trainer.name }} ● {{ course.level }}
            </small>
          </div>
          <div class="col-3">
            <span class="mb-0">{{ course.participant }} {{ describe }}</span>
          </div>
        </div>
      </li>
    </ul>
  </card>
</template>
<script>
export default {
  name: 'Top5Course',
  props: {
    title: {
      type: Object,
      default: () => { },
      require: true
    },
    subTitle: {
      type: Object,
      default: () => { },
      require: true
    },
    list: {
      type: Array,
      default: () => [],
      require: true
    },
    describe: {
      type: String,
      default: "Student",
      require: true
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.header-courses {
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  h3 {
    font-weight: 500;
    font-size: 24px;
    span {
      font-weight: 400;
      font-size: 16px;
      color: $sub-gray;
    }
  }
  .table-heading {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;

    &.not-padding {
      padding: 0;
      padding-left: 15px;
    }
  }
}
.custom-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  &.star {
    margin-right: 2px;
  }
  &.arrow {
    width: 20px;
    height: 20px;
  }
}
</style>