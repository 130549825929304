<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <div class="card-deck flex-column flex-md-row">
          <card>
            <div class="heading-chart">
              <h3>Usage</h3>
              <div class="wrapper-left-chart">
                <base-input>
                  <flat-picker
                    slot-scope="{focus, blur}"
                    @on-open="focus"
                    @on-close="blur"
                    :config="{ allowInput: true, mode: 'range', maxDate: new Date() }"
                    class="form-control datepicker"
                    placeholder="Select date"
                    v-model="dateRangeVideoCourse"
                  ></flat-picker>
                </base-input>
              </div>
            </div>
            <Area
              v-if="dataChartUsage[0].data.length > 0"
              :series="dataChartUsage"
              :titleY="`minutes (m)`"
            />
            <div v-else class="data-empty">Data Tidak Ada</div>
          </card>
        </div>
      </div>
      <div class="container-fluid mt-2">
        <div class="card-deck flex-column flex-md-row">
          <card>
            <div class="heading-chart">
              <h3>Video</h3>
              <div class="wrapper-left-chart">
                <base-input>
                  <flat-picker
                    slot-scope="{focus, blur}"
                    @on-open="focus"
                    @on-close="blur"
                    :config="{ allowInput: true, mode: 'range', maxDate: new Date() }"
                    class="form-control datepicker"
                    placeholder="Select date"
                    v-model="dateRangeVideoCourse"
                  ></flat-picker>
                </base-input>
                <div class="select-row ml-2">
                  <el-select
                    class="select-primary pagination-select"
                    v-model="optionValueVideoCourse"
                    placeholder="Select Course"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in dataCourse"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <Usage
              v-if="dataChartVideo.dataUsage.length > 0"
              :series="dataChartVideo.dataUsage"
              :legend="true"
              :categories="dataChartVideo.categoriesDataUsage"
              :height="285"
            />
            <div v-else class="data-empty">Data Tidak Ada</div>
          </card>
        </div>
      </div>
      <div class="container-fluid mt-2">
        <div class="card-deck flex-column flex-md-row">
          <card>
            <div class="heading-chart">
              <h3>Course</h3>
              <div class="wrapper-left-chart">
                <base-input>
                  <flat-picker
                    slot-scope="{focus, blur}"
                    @on-open="focus"
                    @on-close="blur"
                    :config="{ allowInput: true, mode: 'range', maxDate: new Date() }"
                    class="form-control datepicker"
                    placeholder="Select date"
                    v-model="dateRangeVideoCourse"
                  ></flat-picker>
                </base-input>
                <div class="select-row ml-2">
                  <el-select
                    class="select-primary pagination-select"
                    v-model="optionValueVideoCourse"
                    placeholder="Select Course"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in dataCourse"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <Usage
              v-if="dataChartCourse.dataUsage.length > 0"
              :series="dataChartCourse.dataUsage"
              :legend="true"
              :categories="dataChartCourse.categoriesDataUsage"
              :height="285"
            />
            <div v-else class="data-empty">Data Tidak Ada</div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import { Select, Option } from 'element-ui'
import { mapGetters } from 'vuex'
import Usage from '@/components/Highchart/Usage.vue'
import Area from '@/components/Highchart/Area.vue';
import { getMyCourse, summaryVisiting } from '@/services/student'
import dayjs from 'dayjs'
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  name: "Dashboard-Usage",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    DashboardNavbar,
    Usage,
    Area,
    flatPicker
  },
  data() {
    return {
      routes: [
        {
          name: "ListStudents",
          alias: "List Students"
        },
        {
          name: "Dashboard-Usage",
          alias: "Usage"
        }
      ],
      dataChartUsage: [{
        type: 'area',
        name: 'Usage',
        data: []
      }],
      dateRangeUsage: "",
      dataChartVideo: {
        dataUsage: [],
        categoriesDataUsage: []
      },
      dateRangeVideoCourse: "",
      optionValueVideoCourse: "",
      dataChartCourse: {
        dataUsage: [],
        categoriesDataUsage: []
      },
      dataCourse: [],
      newStartDate: "",
      newEndDate: "",
      newOptionValue: ""
    }
  },
  watch: {
    async dateRangeVideoCourse(val) {
      const tmp = val.split(' ')
      const startDate = tmp[0]
      const endDate = tmp[2]
      if (startDate && !endDate) {
        return await this.getSummary(1)
      }
      if (startDate && endDate) {
        return await this.getSummary(2)
      }
    },
    async optionValueVideoCourse(val) {
      return await this.getSummary()
    },

  },
  computed: {
    ...mapGetters("auth", ["getInfoAuth"]),
    getInfoDate() {
      const today = new Date();
      return new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', }).format(today)
    },
  },
  methods: {
    async getSummary(val) {
      try {
        if (val === 1) {
          this.newStartDate = dayjs(this.dateRangeVideoCourse.split(' ')[0]).format('YYYY-MM-DD')
          this.newEndDate = dayjs(this.dateRangeVideoCourse.split(' ')[0]).format('YYYY-MM-DD')
        } else if (val === 2) {
          this.newStartDate = dayjs(this.dateRangeVideoCourse.split(' ')[0]).format('YYYY-MM-DD')
          this.newEndDate = dayjs(this.dateRangeVideoCourse.split(' ')[2]).format('YYYY-MM-DD')
        }
        this.newOptionValue = this.optionValueVideoCourse
        const payloadSummaryVisiting = {
          userId: +this.$route.params.id,
          startDate: this.newStartDate || "",
          endDate: this.newEndDate || "",
          courseId: this.newOptionValue
        }
        const { data: dataSummary } = await summaryVisiting(payloadSummaryVisiting)
        if (dataSummary.video === "EMPTY_RESULT") {
          this.dataChartVideo.dataUsage = []
          this.dataChartVideo.categoriesDataUsage = []
        } else {
          // seconds to minutes
          dataSummary.video.dataUsage.forEach(item => {
            item.data.forEach((itemUsage, index) => {
              item.data[index] = +(itemUsage / 60).toFixed(2)
            })
          })
          this.dataChartVideo.dataUsage = dataSummary.video.dataUsage
          this.dataChartVideo.categoriesDataUsage = dataSummary.video.categoriesUsage
        }
        if (dataSummary.course === "EMPTY_RESULT") {
          this.dataChartCourse.dataUsage = []
          this.dataChartCourse.categoriesDataUsage = []
        } else {
          // seconds to minutes
          dataSummary.course.dataUsage.forEach(item => {
            item.data.forEach((itemUsage, index) => {
              item.data[index] = +(itemUsage / 60).toFixed(2)
            })
          })
          this.dataChartCourse.dataUsage = dataSummary.course.dataUsage
          this.dataChartCourse.categoriesDataUsage = dataSummary.course.categoriesUsage
        }
        if (dataSummary.app === "EMPTY_RESULT") {
          this.dataChartUsage[0].data = []
        } else {
          const dateParse = dataSummary.app.categoriesUsage.map(item => {
            return item = Date.parse(dayjs(item).format('YYYY-MM-DD'))
          })
          let newArray = []
          dateParse.map((item, index) => newArray.push([item, dataSummary.app.dataUsage[index]]));
          const filterArray = newArray.filter(item => item[1] > 0)
          // secondes to minutes
          filterArray.forEach(item => {
            item[1] = +(item[1] / 60).toFixed(2)
          })
          this.dataChartUsage[0].data = filterArray
        }
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    async initalSummary() {
      try {
        const today = dayjs().format('YYYY-MM-DD')
        const week = dayjs().add(7, 'day').format('YYYY-MM-DD')
        const payloadSummaryVisiting = {
          userId: +this.$route.params.id,
          startDate: today,
          endDate: week
        }
        const { data: dataSummary } = await summaryVisiting(payloadSummaryVisiting)
        if (dataSummary.video === "EMPTY_RESULT") {
          this.dataChartVideo.dataUsage = []
          this.dataChartVideo.categoriesDataUsage = []
        } else {
          // seconds to minutes
          dataSummary.video.dataUsage.forEach(item => {
            item.data.forEach((itemUsage, index) => {
              item.data[index] = +(itemUsage / 60).toFixed(2)
            })
          })
          this.dataChartVideo.dataUsage = dataSummary.video.dataUsage
          this.dataChartVideo.categoriesDataUsage = dataSummary.video.categoriesUsage
        }
        if (dataSummary.course === "EMPTY_RESULT") {
          this.dataChartCourse.dataUsage = []
          this.dataChartCourse.categoriesDataUsage = []
        } else {
          // seconds to minutes
          dataSummary.course.dataUsage.forEach(item => {
            item.data.forEach((itemUsage, index) => {
              item.data[index] = +(itemUsage / 60).toFixed(2)
            })
          })
          this.dataChartCourse.dataUsage = dataSummary.course.dataUsage
          this.dataChartCourse.categoriesDataUsage = dataSummary.course.categoriesUsage
        }
        if (dataSummary.app === "EMPTY_RESULT") {
          this.dataChartUsage[0].data = []
        } else {
          const dateParse = dataSummary.app.categoriesUsage.map(item => {
            return item = Date.parse(dayjs(item).format('YYYY-MM-DD'))
          })
          let newArray = []
          dateParse.map((item, index) => newArray.push([item, dataSummary.app.dataUsage[index]]));
          const filterArray = newArray.filter(item => item[1] > 0)
          // secondes to minutes
          filterArray.forEach(item => {
            item[1] = +(item[1] / 60).toFixed(2)
          })
          this.dataChartUsage[0].data = filterArray
        }
        const { data: dataCourse } = await getMyCourse(this.$route.params.id)
        this.dataCourse = dataCourse

      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    }
  },
  mounted() {
    this.initalSummary()
  }

}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.data-empty {
  width: 100%;
  min-height: 285px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-heading {
  text-align: center;

  .wrapper-photo {
    display: flex;
    height: 120px;
    align-items: center;
    justify-content: center;

    .photo-student {
      height: 100px;
      width: 100px;
      border-radius: 100px;
      box-shadow: 0 7px 4px rgba(0, 0, 0, 0.3);
      overflow: hidden;

      .photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .wrapper-text {
    line-height: 0.2;

    .name-heading {
      font-size: 42px;
      font-weight: 500;
    }

    .date-heading {
      font-size: 20px;
      color: $sub-gray;
      margin-bottom: 20px;
    }
  }
}
.heading-chart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  h3 {
    font-size: 24px;
    font-weight: 500;
  }
  .wrapper-left-chart {
    display: flex;
    align-items: center;
    justify-content: center;
    ::v-deep .form-group {
      margin: 0;
    }
  }
}
.wrapper-right {
  min-width: 360px;

  h3 {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 0px;
  }
  .heading-recently {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    span {
      color: $sub-gray;
      font-size: 18px;
    }
  }
  .body-recently {
    display: flex;
    min-height: 80px;
    // justify-content: space-between;
    align-items: center;

    &.empty-recently {
      justify-content: center;
    }
    .wrapper-account {
      text-align: center;
      line-height: 0;
      max-width: 50px;
      width: 100%;
      &:not(:last-child) {
        margin-right: 10px;
      }
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
      }
    }
  }

  .total-student {
    min-height: 110px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
      font-weight: 400;
      font-size: 28px;
      text-align: center;
      line-height: 1;

      .desc {
        font-size: 32px;
      }
    }

    .custom-icon {
      width: 80px;
      height: 80px;
      margin-right: 50px;
      color: $blue-ar-vr;
    }
  }
}

.custom-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  &.star {
    margin-right: 2px;
  }
  &.arrow {
    width: 20px;
    height: 20px;
  }
}
</style>