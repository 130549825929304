<template>
  <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
</template>
<script>
import Highcharts from 'highcharts'
export default {
  name: "Area",
  props: {
    title: {
      type: String,
      default: null
    },
    titleY: {
      type: String,
      default: null
    },
    height: {
      type: Number,
      default: 285
    },
    legend: {
      type: Boolean,
      default: false
    },
    series: {
      type: Array,
      required: true
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          zoomType: 'x',
          height: this.height,
        },
        title: {
          text: this.title
        },
        legend: {
          enabled: this.legend
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: this.titleY
          }
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
              ]
            },
            marker: {
              radius: 2
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            threshold: null
          }
        },
        series: this.series
      }
    }
  }
}
</script>